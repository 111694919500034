import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LoginPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Client Log-In" />
    <h1 style={styles.header}>Client Log-In</h1>
    <div style={styles.body}>
      <p>
        This page contains the log-in links to your investment account. By clicking on any of the links below you will leave the Coastal Advisory Group website and be directed to the log-in page where your account is domiciled.
      </p>
      <a href="https://www.trustamerica.com/" rel="noopener noreferrer" target="_blank">eTrade Advisor Services</a><br/>
      <a target="_blank" rel="noopener noreferrer" href="https://auth.fccaccessonline.com/NASApp/PerimeterApp/fdccustomerPresentLogin.do?site=D7901DAC15FDA6C4D45A19F8057BDE312161D25520C32E96565B96460FC609054808B4EA6F65E6E6BB987F2E19F51AE0ED849DAFAAED30739DD2CC67074B4D6F">
        First Clearing, LLC,&nbsp;
      </a>
      a non-bank affiliate of Wells Fargo & Co.<br/>
      <a rel="noopener noreferrer" href="https://mainstartrust.com/" target="_blank">Main Star Trust</a>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default LoginPage
